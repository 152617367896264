import React from "react";
import { Layout } from "src/layouts";

const Privacy = () => {
  return (
    <Layout>
      <h1>GetTuned Privacy Policy</h1>
    </Layout>
  );
};

export default Privacy;
